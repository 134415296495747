export const WHITEPAPER =
  "https://tradecurve.gitbook.io/tradecurve-whitepaper/";
export const DEMO_SITE = "https://my.tradecurvemarkets.com/register";
export const TELEGRAM = "https://t.me/tradecurveapp";
export const MEDIUM = "https://medium.com/@tradecurve";
export const INSTAGRAM =
  "https://instagram.com/tradecurveapp?igshid=YmMyMTA2M2Y=";
export const X =
  "https://twitter.com/tradecurveapp?s=11&amp;t=f9jGuk9WwoIepknZHw9iYA";
export const KYC = "https://assuredefi.com/projects/tradecurve";

export const DASHBOARD_LOGIN = process.env
  .REACT_APP_CLIENT_DASHBOARD_URL_LOGIN as string;
export const DASHBOARD_SIGNUP = process.env
  .REACT_APP_CLIENT_DASHBOARD_URL_SIGNUP as string;
