import Footer from "../../components/Footer";
import { useEffect } from "react";
import SimpleLayout from "../../components/Layouts/SimpleLayout";
import { useLocation } from "react-router-dom";

const TermsNService = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = "Terms and Service | Tradecurve";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <SimpleLayout>
        <section className="section-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 remove-last-child-margin">
                <h1>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Terms and Conditions
                    </span>
                  </span>
                </h1>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>ACCEPTANCE</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Please read these Terms and Service ("Terms", "Terms and
                      Service") carefully before using the
                      www.tradecurvemarkets.com website ("us", "we", or "our",
                      "Tradecurve").{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      These Terms explain our obligations to you, and your
                      obligations to us.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      By accessing and using the Service you (the customer
                      "You", "your" and "Customer") will automatically be taken
                      to have read, understood and accepted these Terms and
                      Privacy Policy.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      If you do not agree to be bound by the Terms please cease
                      to access and use this Service and any of the content
                      found within it.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      ELIGIBILITY
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      By or visiting Service you represent and warrant to
                      Tradecurve and agree that:
                    </span>
                  </span>
                </p>

                <ol>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        You are of legal age (18 years old or older) and legal
                        competence.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        If Customer is a company, (i) Customer is duly organized
                        and validly existing under the applicable laws of the
                        jurisdiction of its organization;{" "}
                      </span>
                      <span style={{ verticalAlign: "inherit" }}>
                        (ii) execution and delivery of these Terms have been
                        duly authorized by Customer;{" "}
                      </span>
                      <span style={{ verticalAlign: "inherit" }}>
                        and (iii) each person executing and delivering these
                        Terms and other transactions contemplated on behalf of
                        Customer has been duly authorized by Customer to do so.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        It is legal to use Service in your country of residence.{" "}
                      </span>
                      <span style={{ verticalAlign: "inherit" }}>
                        You represent and warrant that by using the Service you
                        do not violate any laws or regulations applicable to
                        you.
                      </span>
                    </span>
                  </li>
                </ol>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>GENERAL</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      $TCRV token ("Token") is a digital asset created by
                      Tradecurve.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      A Utility Token based on the ERC20 token standard and has
                      many features which will be utilised on the tradecurve
                      trading platform.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>FEES</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      No transaction fees will be applied to the $TCRV token
                      during the presale stages.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      RISK DISCLOSURE
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Cryptocurrencies, tokens and digital assets are extremely
                      volatile and carry a high degree of risk.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Price movements for cryptocurrencies, tokens and digital
                      assets, are influenced by, among other things: changing
                      supply-demand relationships, sentiment and news releases
                      that often cause large price fluctuations.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      By using the services you represent and warrant that:
                    </span>
                  </span>
                </p>

                <ol>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        You have knowledge and experience in cryptocurrencies,
                        tokens and digital assets.
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        You fully understand the risks associated with
                        cryptocurrencies, tokens and digital assets.{" "}
                      </span>
                      <span style={{ verticalAlign: "inherit" }}>
                        Further, you accept that you bear sole liability for all
                        losses resulting from your use of cryptocurrencies,
                        tokens (including the Tradecurve token).
                      </span>
                    </span>
                  </li>
                </ol>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>NO ADVISE</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve does not provide any investment advice or
                      render any opinion to the Customer as to whether the
                      purchase of Token is prudent, timely or suitable.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Nothing in the www.tradecurvemarkets.com website shall be
                      construed a financial advise to you.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      You are solely responsible for determining whether your
                      Token purchase, sale or transaction is appropriate based
                      on your personal objectives, financial circumstances and
                      risk tolerance.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      You must make your own investigations regarding the
                      accuracy, reliability and completion of information
                      provided and consult your professional adviser regarding
                      your specific situation, including your financial
                      situation, objectives and goals.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      SYSTEM AVAILABILITY
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve will use reasonable efforts to ensure that the
                      Service is available at all times, except for reasonable
                      periods of time for maintenance.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>TAXES</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Customer is solely responsible for payment of any and all
                      taxes owed as a result of using Service.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Customer shall indemnify and hold Tradecurve harmless for
                      any and all taxes, penalties, or interest claimed by any
                      agency of government for payments made to Customer
                      pursuant to these Terms.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      USE RESTRICTIONS
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Your permission to use the Site is conditioned upon the
                      following use, posting and conduct restrictions:
                    </span>
                  </span>
                </p>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      You agree that you will not under any circumstances:
                    </span>
                  </span>
                </p>

                <ul>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        use the Service is applicable laws at the country of
                        your location do not permit You to use the Service;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        use the Service for any unlawful purpose or for the
                        promotion of illegal activities;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        use the Servcie in a way that is detrimental to us, our
                        customers or employees;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        disparage, tarnish, or otherwise harm, in our opinion,
                        us and/or the Service;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        use the Service to hide proceeds of illegal activities,
                        or otherwise lauder illegally obtained cryptocurrency,
                        tokens or digital assets;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        distribute any part or parts of the Service without our
                        explicit written permission (we grant the operators of
                        public search engines permission to use spiders to copy
                        materials from the site for the sole purpose of creating
                        publicly-available searchable indices but retain the
                        right to revoke this permission at any time on a general
                        or specific basis);
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        harass, abuse or harm another person or group;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        interfere or attempt to interfere with the proper
                        functioning of the Service;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        make any automated use of the Service or the related
                        systems, or take any action that we deem to impose or to
                        potentially impose an unreasonable or disproportionately
                        large load on our servers or network infrastructure;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        bypass any robot exclusion headers or other measures we
                        take to restrict access to the Service, or use any
                        software, technology, or device to scrape, spider, or
                        crawl the Service or harvest or manipulate data;{" "}
                      </span>
                      <span style={{ verticalAlign: "inherit" }}>
                        use the Service as part of any effort to compete with
                        us;
                      </span>
                    </span>
                  </li>
                  <li>
                    <span style={{ verticalAlign: "inherit" }}>
                      <span style={{ verticalAlign: "inherit" }}>
                        circumvent, disable or otherwise interfere with any
                        security-related features of the Service or features
                        that prevent or restrict use or copying of content, or
                        enforce limitations on use of the Service or the content
                        accessible via the Service.
                      </span>
                    </span>
                  </li>
                </ul>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      IRREVERSIBLE TRANSACTIONS
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Exercise extreme caution and double check all information
                      you are entering when purchasing/selling Token at
                      www.tradecurvemarkets.com website, any cryptocurrency
                      exchange or when transferring Token to another account.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      By definition, cryptocurrency, token or digital asset
                      transactions are final.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Once the transaction has been carried out it cannot be
                      reversed.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      You agree not to hold us liable for your monetary loss
                      resulting from such an error.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      INTELLECTUAL PROPERTY
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Unless otherwise indicated, www.tradecurvemarkets.com
                      website is our proprietary property and all source code,
                      databases, functionality, software, website designs,
                      audio, video, text, photographs, and graphics on the
                      www.tradecurvemarkets.com website (collectively, the
                      "Content") and the trademarks, logos contained therein
                      (the "Marks") are owned or controlled by us or licensed to
                      us, and are protected by copyright and trademark laws and
                      various other intellectual property rights.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      The Content and the Marks are provided on the Site "AS IS"
                      for your information and personal use only.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Except as expressly provided in these Terms of Service, no
                      part of the Site and no Content or Marks may be copied,
                      reproduced, aggregated, republished, uploaded, posted,
                      publicly displayed, encoded, translated, transmitted,
                      distributed, sold, licensed,{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      or otherwise exploited for any commercial purpose
                      whatsoever, without our express prior written permission.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Provided that you are eligible to use the
                      www.tradecurvemarkets.com website, you are granted a
                      limited license to access and use the
                      www.tradecurvemarkets.com website and to download or print
                      a copy of any portion of the Content to which you have
                      properly increased access solely for your personal,
                      non-commercial use.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      We reserve all rights not expressly granted to you in and
                      to the www.tradecurvemarkets.com website, the Content and
                      the tradecurve.
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      io website and to download or print a copy of any portion
                      of the Content to which you have properly gained access
                      solely for your personal, non-commercial use.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      We reserve all rights not expressly granted to you in and
                      to the www.tradecurvemarkets.com website, the Content and
                      the tradecurve.
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      io website and to download or print a copy of any portion
                      of the Content to which you have properly gained access
                      solely for your personal, non-commercial use.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      We reserve all rights not expressly granted to you in and
                      to the www.tradecurvemarkets.com website, the Content and
                      the Marks.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      LINKS TO OTHER SITES
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Links to non tradecurve websites are provided solely as
                      pointers to websites that may be useful to Tradecurve
                      customers.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve has no control over the content on such
                      websites.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      If You choose to visit to a website not controlled by
                      Tradecurve, We make no warranties, either expressed or
                      implied, concerning the content of such site, including
                      the accuracy, completeness, reliability, or suitability
                      thereof for any particular purpose, nor does Tradecurve
                      warrant that that such site or content is devoid of
                      viruses or other contamination.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve does not guarantee the authenticity of
                      documents on the Internet.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Links to non-Tradecurve sites do not imply any endorsement
                      of or responsibility for the opinions, ideas, products,
                      information, or services offered at such sites, or any
                      representation regarding the content at such sites.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      You agree and acknowledge that we do not endorse the
                      services offered on third-party websites and you shall
                      hold us harmless from any harm caused by such websites.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Additionally, you shall hold us harmless from any losses
                      sustained by you or harm caused to you relating to or
                      resulting in any way from any third-party website.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      TERMINATION
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve may terminate you access to this Website at any
                      time in the event of breach of these Terms.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      All provisions of the Agreement which by their nature
                      should termination shall survive shall survive
                      termination, including, without limitation, ownership
                      provisions, warranty disclaimers, indemnity, and
                      limitations of liability.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      DISCLAIMER OF LIABILITY
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve makes no warranty that the functionality of
                      www.tradecurvemarkets.com website will be uninterrupted.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Furthermore, we do not guarantee that the information
                      provided on www.tradecurvemarkets.com website is correct,
                      complete or up-to-date.
                    </span>
                  </span>
                </p>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve will not be held liable for confirmation of any
                      transaction on any blockchain network as we hold no
                      control over any blockchain network.
                    </span>
                  </span>
                </p>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve will not be liable for any consequential,
                      incidental, special, direct, or indirect damages
                      (including but not limited to lost profits, losses or
                      damages that result from use or loss of use of the
                      Tradecurve services and third party content, inconvenience
                      or delay ).{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      this is true even if Tradecurve has been advised of the
                      possibility of such damages or losses.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve will not be liable to you or anyone else
                      (whether under contract, tort (including negligence) or
                      otherwise) for any loss resulting from a cause over which
                      Tradecurve does not have direct control.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      This includes, but not limited to, failure of electronic
                      or mechanical equipment or communications lines (including
                      telephone, cable and internet), script error, severe or
                      extraordinary weather (including flood, earthquake, or
                      other act of god), fire, war, insurrection,{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      terrorist act, riot, labor dispute and other labor
                      problems, accident, emergency or action of government.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Tradecurve will not be liable for losses resulting from
                      incorrect information entered when purchasing, selling or
                      transferring Token.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>INDEMNITY</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      You agree to defend, indemnify, and hold us harmless,
                      including our subsidiaries, affiliates, and all of our
                      respective officers, agents, partners, and employees, from
                      and against any loss, damage, liability, claim, or demand,
                      including reasonable attorneys fees and expenses, made by
                      any third party due to or arising out of: (1) use of the
                      Service;{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      (2) breach of these Terms;{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      (3) any breach of your representations and warranties set
                      forth in these Terms;{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      (4) your violation of the rights of a third party,
                      including but not limited to intellectual property rights;{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      or (5) any overt harmful act toward any other user of the
                      www.tradecurvemarkets.com website.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      Notwithstanding the foregoing, we reserve the right, at
                      your expense, to assume the exclusive defense and control
                      of any matter for which you are required to indemnify us,
                      and you agree to cooperate,{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      at your expense, with our defense of such claims.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      We will use reasonable efforts to notify you of any such
                      claim, action, or proceeding which is subject to this
                      indemnification upon becoming aware of it.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      CHANGES AND MODIFICATIONS
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      We reserve the right, in our sole discretion, to make
                      changes or modifications to these Terms of Service at any
                      time and for any reason.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      We will alert you about any changes by updating the "Last
                      updated" date of these Terms of Service, and you waive any
                      right to receive specific notice of each such change.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      It is your responsibility to periodically review these
                      Terms of Service to stay informed of updates.{" "}
                    </span>
                    <span style={{ verticalAlign: "inherit" }}>
                      You will be subject to, and will be considered to have
                      been made aware of and to have accepted, the changes in
                      any revised Terms of Service by your continued use of the
                      www.tradecurvemarkets.com website after the date such
                      revised Terms of Service are posted.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      JURISDICTION AND APPLICABLE LAW
                    </span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      Except to the extent of any applicable law provides
                      otherwise, the Agreement and any access to or use of our
                      Services will be governed by the laws of St.Lucia. The
                      proper venue for any disputes arising out of or relating
                      to the Agreement and any access to or use of our Services
                      will be in the courts located in St.Lucia.
                    </span>
                  </span>
                </p>

                <h5>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>CONTACT</span>
                  </span>
                </h5>

                <p>
                  <span style={{ verticalAlign: "inherit" }}>
                    <span style={{ verticalAlign: "inherit" }}>
                      If you have any questions relating to these Terms, please
                      contact us via email on Send Mail:
                      Info@tradecurvemarkets.com&nbsp;
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </SimpleLayout>

      <Footer />
    </>
  );
};

export default TermsNService;
