import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { key: "gb", name: "English" },
  { key: "de", name: "Germany" },
  { key: "es", name: "Spanish" },
  { key: "fr", name: "French" },
  { key: "pt", name: "Portuguese" },
  { key: "tr", name: "Turkish" },
  { key: "ru", name: "Russian" },
  { key: "it", name: "Italian" },
  { key: "id", name: "Indonesia" },
  { key: "ni", name: "Arabic" },
  { key: "cn", name: "China" },
];

const LANGUAGE_SELECTOR_ID = "language-selector";

export const LanguageMenu = () => {
  const [isShowingModel, setShowingModel] = useState(false);
  const { i18n } = useTranslation();

  const selectedLanguage = languages.find(
    (language) => language.key === i18n.language
  );

  const handleLanguageChange = async (language: {
    key: string;
    name: string;
  }) => {
    await i18n.changeLanguage(language.key);
    setShowingModel(false);
  };

  useEffect(() => {
    const handleWindowClick = (event: any) => {
      const target = event.target.closest("button");
      if (target && target.id === LANGUAGE_SELECTOR_ID) {
        return;
      }
      setShowingModel(false);
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, []);

  return (
    <>
      <button
        onClick={() => {
          setShowingModel((prev) => !prev);
        }}
        id={LANGUAGE_SELECTOR_ID}
        className="btn btn-link btn-sm border-0 flex flex-row items-center gap-2 text-black"
      >
        <span className={`fi fi-${selectedLanguage?.key} mr-[2px]`} />
        <span className="max-md:hidden">{selectedLanguage?.name}</span>
      </button>

      {isShowingModel && (
        <div
          className="origin-top-right absolute top-11 right-12 mt-2.5 p-3 rounded-sm shadow-lg bg-white"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby={LANGUAGE_SELECTOR_ID}
        >
          <div className="py-[1px] grid grid-cols-1 gap-2.5" role="none">
            {languages.map((language, index) => {
              return (
                <div
                  key={language.key}
                  onClick={() => handleLanguageChange(language)}
                  className={classNames(
                    selectedLanguage?.key === language.key && "bg-border-gray",
                    "px-1 py-1 text-sm text-start items-center inline-flex hover:bg-border-gray text-guerrilla cursor-pointer"
                  )}
                  role="menuitem"
                >
                  <span className={`fi fi-${language.key} mr-[2px]`}></span>
                  <span className="truncate ml-2.5 font-sans">
                    {language.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
