import { useTranslation } from "react-i18next";
import { information } from "../../../information";

const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <section className="section text-light bg-black relative z-[-1]">
      <div className="absolute bg-why_us bg-no-repeat bg-cover bg-center inset-0 opacity-5 z-0"></div>

      <div className="container">
        <div className="text-center">
          <h3 className="mb-20 font-bold text-white z-10">
            {t("why_us_label")}
          </h3>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-10 mb-24">
          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_1.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_1")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_1_description")}
            </div>
          </div>

          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_2.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_2")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_2_description")}
            </div>
          </div>

          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_3.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_3")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_3_description")}
            </div>
          </div>

          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_4.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_4")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_4_description")}
            </div>
          </div>

          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_5.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_5")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_5_description")}
            </div>
          </div>

          <div className="flex flex-col">
            <img
              className="w-[60px] h-[60px] md:w-[80px] md:h-[80px] mb-5"
              src="/img/why-us/why_us_6.svg"
              alt="WhyUs"
            />
            <div className="font-bold text-lg md:text-2xl text-white mb-3">
              {t("why_us_category_6")}
            </div>
            <div className="font-medium font-inter text-sm text-gray">
              {t("why_us_category_6_description")}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 mb-10">
          <div className="md:col-span-7 flex flex-col justify-center md:w-10/12">
            <h3 className="mb-8 font-bold text-white">
              {t("the_token_label")}
            </h3>
            <p className="font-medium text-gray font-inter text-base">
              {t("the_token_description")}
            </p>
          </div>

          <div className="md:col-span-5 flex flex-col justify-center items-center">
            <div className="w-8/12 md:w-full lg:w-10/12">
              <img src="/img/why-us/token-icon.png" alt="Icon" />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-10">
          <div className="max-md:text-center flex flex-col">
            <div className="font-bold text-lg md:text-xl text-green mb-3">
              {t("symbol")}:{" "}
              <span className="text-gray font-bold">{information.symbol}</span>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="max-md:text-center font-bold text-lg md:text-xl text-green mb-3">
              {t("chain")}:{" "}
              <span className="text-gray font-bold">{information.chain}</span>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="max-md:text-center font-bold text-lg md:text-xl text-green mb-3">
              {t("price")}:{" "}
              <span className="text-gray font-bold">{information.price}</span>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="max-md:text-center font-bold text-lg md:text-xl text-green mb-3">
              {t("supply")}:{" "}
              <span className="text-gray font-bold">{information.supply}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
