import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DEMO_SITE, INSTAGRAM, MEDIUM, TELEGRAM, WHITEPAPER, X } from "../urls";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className="footer">
      <div className="container">
        <div className="flex flex-col items-center">
          <a
            className="footer__logo mb-8 flex justify-center items-center"
            href="/"
          >
            <img src="/img/logo-light.svg" alt="Tradecurve" />
          </a>
          <div className="grid grid-cols-6 md:grid-cols-5 gap-5 md:gap-10 mb-8">
            <a
              className="col-span-2 md:col-span-1 footer-nav__link text-sm md:text-base font-inter text-center"
              href="#features"
            >
              {t("features")}
            </a>
            <a
              className="col-span-2 md:col-span-1 footer-nav__link text-sm md:text-base font-inter text-center"
              href="#about"
            >
              {t("about")}
            </a>
            <Link
              className="col-span-2 md:col-span-1 footer-nav__link text-sm md:text-base font-inter text-center"
              target="_blank"
              to={DEMO_SITE}
            >
              {t("demo")}
            </Link>
            <a
              className="col-span-3 md:col-span-1 footer-nav__link text-sm md:text-base font-inter text-center"
              href="#faqs"
            >
              {t("faqs")}
            </a>
            <Link
              className="col-span-3 md:col-span-1 footer-nav__link text-sm md:text-base font-inter text-center"
              target="_blank"
              to={WHITEPAPER}
            >
              {t("white_paper")}
            </Link>
          </div>

          <ul className="social-list gap-10 mb-8">
            <li className="social-list__item m-0">
              <Link
                className="social-list__link bg-none border-none"
                to={X}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icomoon-twitter"></span>
              </Link>
            </li>
            <li className="social-list__item m-0">
              <Link
                className="social-list__link bg-none border-none"
                to={TELEGRAM}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icomoon-telegram"></span>
              </Link>
            </li>
            <li className="social-list__item m-0">
              <Link
                className="social-list__link bg-none border-none"
                to={INSTAGRAM}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icomoon-instagram"></span>
              </Link>
            </li>
            <li className="social-list__item m-0">
              <Link
                className="social-list__link bg-none border-none"
                to={MEDIUM}
                target="_blank"
                rel="noreferrer"
              >
                <span className="icomoon-social"></span>
              </Link>
            </li>
          </ul>

          <div className="mb-8 text-center col-md-8">
            <p className="text-sm md:text-base font-inter text-white">
              {t("footer_description")}
            </p>
          </div>

          <div className="flex flex-row gap-10 mb-8">
            <Link
              className="footer-nav__link text-sm md:text-base font-inter"
              to="/terms-and-service"
            >
              {t("terms_and_conditions")}
            </Link>
            <Link
              className="footer-nav__link text-sm md:text-base font-inter"
              to="/privacy-policy"
            >
              {t("privacy_policy")}
            </Link>
          </div>

          <div className="mb-8 flex gap-5 md:gap-10">
            <a
              className="footer-nav__link text-sm md:text-base font-inter text-white"
              href="mailto:Info@tradecurvemarkets.com"
            >
              Email:{" "}
              <span className="text-green">Info@tradecurvemarkets.com</span>
            </a>
            <a
              className="footer-nav__link text-sm md:text-base font-inter text-white"
              href="mailto:Support@tradecurvemarkets.com"
            >
              Support:{" "}
              <span className="text-green">Support@tradecurvemarkets.com</span>
            </a>
          </div>

          <p className="text-sm md:text-base font-inter text-white">
            Tradecurvemarkets.com ©2023 All rights reserved.{" "}
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
