import { Chart } from "chart.js/auto";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const Allocation = () => {
  const { t } = useTranslation();
  const chartRef = useRef<any>();

  useEffect(() => {
    const ctx = document.getElementById(
      "chart-token-allocation"
    ) as HTMLCanvasElement;
    if (chartRef.current) {
      chartRef.current?.destroy();
    }
    if (typeof ctx != "undefined" && ctx != null) {
      chartRef.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [25.3, 15, 14, 10, 10, 23.7, 2],
              backgroundColor: [
                "#11BA77",
                "#AC3E84",
                "#49247E",
                "#CFC419",
                "#F3A377",
                "#60BBE3",
                "#D9D9D9",
              ],
            },
          ],
        },
        options: {
          borderColor: "#081714",
          cutout: "94%",
          plugins: {
            tooltip: {
              enabled: false,
            },
          },
        },
      });
    }
  }, []);

  return (
    <section className="section text-light bg-black indent-for-round-bg">
      <div className="container">
        <div className="text-center">
          <h3 className="mb-8 font-bold text-white">{t("token_allocation")}</h3>
        </div>

        <div className="doughnut-chart">
          <div className="doughnut-chart__label h6 mb-md-0 green position-1">
            25.3%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("public_presale")}</span>
          </div>
          <div className="doughnut-chart__label h6 mb-md-0 pink position-2">
            15%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("team_founders")}</span>
          </div>
          <div className="doughnut-chart__label h6 mb-md-0 purple position-3">
            14%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("reserve")}</span>
          </div>
          <div className="doughnut-chart__label h6 mb-md-0 yellow position-4">
            10%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("listings")}</span>
          </div>

          <div className="doughnut-chart__canvas">
            <canvas
              id="chart-token-allocation"
              style={{
                display: "block",
                boxSizing: "border-box",
                height: "250px",
                width: "250px",
              }}
              width="400"
              height="400"
            ></canvas>
          </div>

          <div className="doughnut-chart__label h6 mb-md-0 orange position-5 reversed">
            10%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("marketing")}</span>
          </div>
          <div className="doughnut-chart__label h6 mb-md-0 blue position-6 reversed">
            23.7%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("burn")}</span>
          </div>
          <div className="doughnut-chart__label h6 mb-md-0 gray position-7 reversed">
            2%<span className="doughnut-chart__label-color"></span>
            <span className="text-light">{t("legal_advisory")}</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Allocation;
