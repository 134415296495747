import { useTranslation } from "react-i18next";

const Sponsors = () => {
  const { t } = useTranslation();
  return (
    <section className="section bg-black">
      <div className="container">
        <div className="text-center">
          <h3 className="mb-8 font-bold text-white">{t("powered_by")}</h3>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4">
          <div className="card-logo bg-none">
            <div className="card-logo__inner h-[6rem] bg-transparent">
              <img
                className="card-logo__img"
                src="/img/card-logo/power_by_1.svg"
                alt="Logo"
              />
            </div>
          </div>
          <div className="card-logo bg-none">
            <div className="card-logo__inner h-[6rem] bg-transparent">
              <img
                className="card-logo__img"
                src="/img/card-logo/power_by_2.svg"
                alt="Logo"
              />
            </div>
          </div>
          <div className="card-logo bg-none">
            <div className="card-logo__inner h-[6rem] bg-transparent">
              <img
                className="card-logo__img"
                src="/img/card-logo/power_by_3.png"
                alt="Logo"
              />
            </div>
          </div>
          <div className="card-logo bg-none">
            <div className="card-logo__inner h-[6rem] bg-transparent">
              <img
                className="card-logo__img"
                src="/img/card-logo/power_by_4.png"
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sponsors;
