import { useTranslation } from "react-i18next";

const Markets = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <div className="md:text-center">
          <h3 className="mb-12 font-bold text-center">{t("markets_title")}</h3>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-6 gap-14">
          <img
            src={"/img/market_1.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />
          <img
            src={"/img/market_2.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />
          <img
            src={"/img/market_3.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />
          <img
            src={"/img/market_4.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />
          <img
            src={"/img/market_5.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />

          <img
            src={"/img/market_6.png"}
            alt=""
            className="object-contain w-[90px] h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Markets;
