import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KYC } from "../../../urls";

const Secure = () => {
  const { t } = useTranslation();
  return (
    <section className="section bg-black text-light">
      <div className="container bg-gradient-to-b from-white/30 to-white/0 p-[1px]">
        <div className="bg-black">
          <div className="bg-gradient-to-b from-[#121212]/30 to-[#131313]/0 p-10">
            <div className="text-center mb-10">
              <h3 className="mb-8 font-bold text-white">{t("secure_label")}</h3>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <p className="font-inter text-base text-gray mb-8">
                    {t("secure_description")}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid max-lg:grid-rows-2 lg:grid-cols-2 gap-12">
              <div className="bg-gradient-to-b from-white/30 to-white/0 p-[1px] rounded-lg relative overflow-hidden">
                <div className="bg-black h-full rounded-lg">
                  <div className="bg-gradient-to-b from-[#121212]/30 to-[#131313]/0 rounded-lg grid grid-cols-12 pl-10 h-full">
                    <img
                      src="/img/secure/kyc.png"
                      alt="KYC"
                      className="h-auto w-auto object-contain col-span-2 py-8"
                    />
                    <div className="flex flex-col gap-3 items-start col-span-9 p-8">
                      <div className="flex items-center gap-4">
                        <h4 className="mb-0 font-medium">KYC</h4>
                        <img
                          src="/img/secure/pdf.svg"
                          alt="PDF"
                          className="h-6 w-auto object-contain"
                        />
                      </div>
                      <p className="mb-0 text-sm text-gray font-medium">
                        {t("kyc_description")}
                      </p>
                    </div>

                    <div className="col-span-1 flex">
                      <Link
                        to={KYC}
                        target="_blank"
                        className="bg-green w-full h-full top-0 flex items-center justify-center"
                      >
                        <img
                          src="/img/secure/download.svg"
                          className="px-2"
                          alt="Download"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-gradient-to-b from-white/30 to-white/0 p-[1px] rounded-lg relative overflow-hidden">
                <div className="bg-black h-full rounded-lg">
                  <div className="bg-gradient-to-b from-[#121212]/30 to-[#131313]/0 rounded-lg grid grid-cols-12 pl-10 h-full">
                    <img
                      src="/img/secure/audit.png"
                      alt="Audit"
                      className="h-auto w-auto object-contain col-span-2 py-8"
                    />
                    <div className="flex flex-col gap-3 items-start col-span-9 p-8">
                      <div className="flex items-center gap-4">
                        <h4 className="mb-0 font-medium">{t("audit")}</h4>
                        <img
                          src="/img/secure/pdf.svg"
                          alt="PDF"
                          className="h-6 w-auto object-contain"
                        />
                      </div>
                      <p className="mb-0 text-sm text-gray font-medium">
                        {t("audit_description")}
                      </p>
                    </div>

                    <div className="col-span-1 flex">
                      <Link
                        to={"/docs/audit.pdf"}
                        target="_blank"
                        className="bg-green w-full h-full top-0 flex items-center justify-center"
                      >
                        <img
                          src="/img/secure/download.svg"
                          className="px-2"
                          alt="Download"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Secure;
