import { ReactNode, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import RootLayout from "./RootLayout";
import { useTranslation } from "react-i18next";
import $ from "jquery";

type LayoutProps = {
  children: ReactNode;
};

const SimpleLayout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const header = $(".js-header");

    const handleScroll = () => {
      if ($(window).scrollTop() > 10) {
        header.addClass("active");
      } else {
        header.removeClass("active");
      }
    };

    $(window).on("scroll", handleScroll);

    if ($(document).scrollTop() > 10) {
      header.addClass("active");
    }

    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []);

  return (
    <RootLayout>
      <header className="header w-full fixed js-header z-50">
        <Disclosure as="nav" className="w-full relative header__main-content">
          {({ open }) => (
            <>
              <div className="container relative">
                <div className="flex h-16 items-center justify-between">
                  <div className="col-auto">
                    <a className="header__logo" href="/">
                      <img src="/img/logo-dark.svg" alt="Tradecurve" />
                    </a>
                  </div>
                  <div className="hidden lg:block divide-x"></div>
                </div>
              </div>
            </>
          )}
        </Disclosure>
      </header>
      <main className="main">{children}</main>
    </RootLayout>
  );
};

export default SimpleLayout;
