import Footer from "../../components/Footer";
import { useEffect } from "react";
import SimpleLayout from "../../components/Layouts/SimpleLayout";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = "Privacy Policy | Tradecurve";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <SimpleLayout>
        <section className="section-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 remove-last-child-margin">
                <h1>Privacy Policy</h1>

                <p>
                  This Privacy Policy explains how www.tradecurvemarkets.com and
                  its affiliates collects, uses, shares, and protects user
                  information obtained through the Tradecurve website found at
                  www.tradecurvemarkets.com The terms “we,” “us,” and “our”
                  refer to Tradecurve and its affiliates. When we ask for
                  certain personal information from users it is either because
                  we are required by law to collect this information or it is
                  relevant for specified purposes. Any non-required information
                  you provide to us is done so voluntarily. You decide whether
                  to provide us with non-required information; you may not be
                  able to access or utilize all of our Services if you choose
                  not to.
                </p>

                <p>
                  By using the Tradecurve website, you consent to the data
                  practices described in this Privacy Policy. On occasion,
                  Tradecurve may revise this Privacy Policy to reflect changes
                  in law or our personal data collection and use practices. If
                  material changes are made to this Privacy Policy, the changes
                  will be announced by posting on the website and the Last
                  Revised date will be updated. We will ask for your consent
                  before using your information for any purpose that is not
                  covered in this Privacy Policy.
                </p>

                <p>
                  The latest Privacy Policy has incorporated elements from the
                  General Data Protection Regulation, as we act in accordance to
                  its personal information processing rules within the European
                  Economic Area.
                </p>

                <h5>INFORMATION THAT TRADECURVE COLLECTS</h5>

                <p>
                  We want you to understand the types of information we collect
                  when you register for and use Tradecurve’s services.
                </p>

                <p>Information you provide to us upon registration:</p>

                <ul>
                  <li>
                    When you create a Tradecurve account, you provide us with
                    personal information that includes your contact information
                    (email address, name, and a password). You can also choose
                    to add a phone number for SMS for two-factor authentication
                    verification for improved security.
                  </li>
                </ul>

                <p>Information we collect as you use our services:</p>

                <ul>
                  <li>
                    A. Service Usage Information
                    <br />
                    Through your use of the Tradecurve platform, we also monitor
                    and collect tracking information related to usage, such as
                    access date and time, device identification, operating
                    system, browser type and IP address. This information may be
                    directly obtained by Tradecurve or through third party
                    services. This service usage data helps our systems to
                    ensure that our interface is accessible for users across all
                    platforms and can aid during criminal investigations.
                  </li>
                  <li>
                    B. Transaction Information
                    <br />
                    For all personal and enterprise user accounts, we collect
                    transaction information, including deposit snapshots,
                    account balances, trade history, withdrawals, order activity
                    and distribution history. This transaction data is monitored
                    for suspicious trading activity for user fraud protection,
                    and legal case resolution.
                  </li>
                </ul>

                <h5>WHY DOES TRADECURVE COLLECT THIS INFORMATION?</h5>

                <ul>
                  <li>
                    A. To provide and maintain our services
                    <br />
                    We use the information collected to deliver our services and
                    to verify user identity. We use the IP address and unique
                    identifiers stored in your device’s cookies to help us
                    authenticate your identity and to provide our services.
                    Given our legal obligations and system requirements, we
                    cannot provide you with our services without data, like
                    identification, contact information and transaction-related
                    information.
                  </li>
                  <li>
                    B. To protect our users
                    <br />
                    We use the information collected to protect our platform,
                    its users’ accounts, and archives. We use IP addresses and
                    cookie data to protect against automated abuse such as spam,
                    phishing and Distributed Denial of Service attacks. We
                    analyze trading activity with the goal of detecting
                    suspicious behaviour early to prevent potential fraud and
                    loss of funds to bad actors.
                  </li>
                  <li>
                    C. To comply with legal and regulatory requirements
                    <br />
                    Respect for the privacy and security of the data you store
                    with Tradecurve informs our approach to complying with
                    regulations, governmental requests and user-generated
                    inquiries. We will not disclose or provide any personal
                    information to third party sources without review from our
                    legal team and/or prior consent from the user.
                  </li>
                  <li>
                    D. To measure site performance
                    <br />
                    We actively measure and analyze data to understand how our
                    services are used. This review activity is conducted by our
                    operations team to continually improve our platform’s
                    performance and to resolve issues with the user experience.
                    <br />
                    We continuously monitor our systems' activity information
                    and communications with users to look for and quickly fix
                    problems.
                  </li>
                  <li>
                    E. To communicate with you
                    <br />
                    We use personal information collected, like an email address
                    to interact with users directly when providing customer
                    support on a ticket or to keep you informed on logins,
                    transactions, and security. Without processing your personal
                    information for confirming each communication, we will not
                    be able to respond to your submitted requests, questions and
                    inquiries. All direct communications are kept confidential
                    and reviewed internally for accuracy.
                  </li>
                  <li>
                    F. To enforce our Terms of Use and other agreements
                    <br />
                    It is very important for us and our customers that we
                    continually review, investigate and prevent any potentially
                    prohibited or illegal activities that violate our Terms of
                    Use. For the benefit of our entire user base, we carefully
                    enforce our agreements with third parties and actively
                    investigate violations of our posted Terms of Use.
                    Tradecurve reserves the right to terminate the provision of
                    service to any user found engaging in activities that
                    violate our Terms of Use.
                  </li>
                </ul>

                <h5>HOW DOES TRADECURVE PROTECT USER DATA?</h5>

                <p>
                  Tradecurve has implemented a number of security measures to
                  ensure that your information is not lost, abused, or altered.
                  Our data security measures include, but are not limited to:
                  PCI Scanning, secure sockets layer encryption technology,
                  pseudonymisation, internal data access restrictions, and
                  strict physical access controls to buildings and files. Please
                  note that it is impossible to guarantee 100% secure
                  transmission of data over the Internet nor method of
                  electronic storage. As such, we request that you understand
                  the responsibility to independently take safety precautions to
                  protect your own personal information. If you suspect that
                  your personal information has been compromised, especially
                  account and/or password information, please lock your account
                  and contact Tradecurve customer service immediately via email
                  on Info@tradecurvemarkets.com&nbsp;
                </p>

                <h5>CONTACT US</h5>

                <p>
                  We are committed to respecting the freedoms and rights of all
                  our Tradecurve users who have placed their trust in our
                  service. If you should have any questions or concerns
                  regarding this Privacy Policy, or if you would like to file a
                  data protection request, please refer to our FAQ and contact
                  us via email on Info@tradecurvemarkets.com&nbsp;
                </p>
              </div>
            </div>
          </div>
        </section>
      </SimpleLayout>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
