import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { StageProvider } from "./contexts/stage.context";
import Home from "./pages/home";
import $ from "jquery";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from "./pages/404";
import PrivacyPolicy from "./pages/privacy-policy";
import TermsNService from "./pages/terms-and-service";
import { getUserIPData } from "./apis/ipapi";
import { getGeoUserIPData } from "./apis/geoapi";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/terms-and-service",
      element: <TermsNService />,
    },
    {
      path: "/bonus-terms",
      element: <TermsNService />,
    },
    {
      path: "/*",
      element: <NotFound />,
    },
  ],
  {}
);

function App() {
  let resizeTimer = useRef<any>();
  const [isShowingRestrictedModal, $isShowingRestrictedModal] = useState(false);

  function closeModal() {
    $isShowingRestrictedModal(false);
  }
  function openModal() {
    $isShowingRestrictedModal(true);
  }

  useEffect(() => {
    const handleResize = () => {
      $("body").addClass("resize-animation-stopper");
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(function () {
        $("body").removeClass("resize-animation-stopper");
      }, 400);
    };
    $(window).on("resize", handleResize);
    return () => {
      $(window).off("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let country = localStorage.getItem("@userip");
      if (country) {
        try {
          const { data } = await getGeoUserIPData();
          if (data?.["IPv4"] === JSON.parse(country)?.["ip"]) {
            if (JSON.parse(country)?.["country_name"] === "United Kingdom") {
              openModal();
              return;
            }
          }
        } catch (error) {}
      }

      try {
        const { data } = await getUserIPData();
        localStorage.setItem("@userip", JSON.stringify(data));

        if (data?.country_name === "United Kingdom") {
          openModal();
        }
      } catch (error) {}
    })();
  }, []);

  return (
    <StageProvider>
      <RouterProvider router={router} />

      <Transition appear show={isShowingRestrictedModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="font-bold text-lg text-black mb-3"
                  >
                    ACCESS RESTRICTED
                  </Dialog.Title>

                  <div className="font-medium font-inter text-sm text-gray">
                    For legal reasons, this website and its services are not
                    available in your country. If you are using a VPN, please
                    disable it and try again.
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm rounded-lg"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </StageProvider>
  );
}

export default App;
