import Layout from "../../components/Layouts/Layout";
import About from "./components/About";
import Allocation from "./components/Allocation";
import Choice from "./components/Choice";
import FAQs from "./components/FAQs";
import Footer from "../../components/Footer";
import Roadmap from "./components/Roadmap";
import Secure from "./components/Secure";
import Help from "./components/Help";
import HowDoesItWork from "./components/HowDoesItWork";
import Sponsors from "./components/Sponsors";
import Utilities from "./components/Utilities";
import Welcome from "./components/Welcome";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Markets from "./components/Markets";
import WhyUs from "./components/WhyUs";

const Home = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = "Tradecurve Official Presale Is Live";
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [pathname]);

  return (
    <>
      <Layout>
        <Welcome />
        <About />
        <HowDoesItWork />
        <Markets />
        <WhyUs />
        <Utilities />
        <Choice />
        <Secure />
        <Sponsors />
        <Allocation />
        <Help />
        <Roadmap />
        <FAQs />
      </Layout>

      <Footer />
    </>
  );
};

export default Home;
