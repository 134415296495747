import { ReactNode, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import RootLayout from "./RootLayout";
import { LanguageMenu } from "../LanguageMenu";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Link } from "react-router-dom";
import {
  DASHBOARD_LOGIN,
  DASHBOARD_SIGNUP,
  DEMO_SITE,
  WHITEPAPER,
} from "../../urls";

type LayoutProps = {
  children: ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const header = $(".js-header");

    const handleScroll = () => {
      if ($(window).scrollTop() > 10) {
        header.addClass("active");
      } else {
        header.removeClass("active");
      }
    };

    $(window).on("scroll", handleScroll);

    if ($(document).scrollTop() > 10) {
      header.addClass("active");
    }

    return () => {
      $(window).off("scroll", handleScroll);
    };
  }, []);

  return (
    <RootLayout>
      <header className="header w-full fixed js-header z-50">
        {/* <div className="header__top-alert">
          <div className="container">
            <div className="d-flex align-items-center justify-content-center">
              <div className="header__top-alert-logo">
                <img
                  className="of-contain"
                  src="/img/logo-small-light.svg"
                  alt="Tradecurve"
                />
              </div>
              <span className="fw-semibold">
                {t("header_banner_label")}{" "}
                <Link to={DASHBOARD_SIGNUP} className="fw-bold text-white">
                  {t("header_banner_description")}
                </Link>
              </span>
            </div>
          </div>
        </div> */}

        <Disclosure as="nav" className="w-full relative header__main-content">
          {({ open }) => (
            <>
              <div className="container relative">
                <div className="flex items-center justify-between">
                  <div className="col-auto">
                    <a className="header__logo" href="/">
                      <img src="/img/logo-dark.svg" alt="Tradecurve" />
                    </a>
                  </div>
                  <div className="hidden lg:block divide-x">
                    <div className="flex items-center">
                      <ul className="header__btn-list">
                        <li className="header__btn-list-item">
                          <Link
                            className="btn btn-danger btn-sm text-white rounded-lg"
                            to={DEMO_SITE}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t("try_demo")}
                          </Link>
                        </li>
                        <li className="header__btn-list-item">
                          <Link
                            className="btn btn-dark btn-sm rounded-lg"
                            to={DASHBOARD_LOGIN}
                          >
                            {t("sign_in")}
                          </Link>
                        </li>
                        <li className="header__btn-list-item">
                          <Link
                            className="btn btn-primary btn-sm rounded-lg"
                            to={DASHBOARD_SIGNUP}
                          >
                            {t("get_started")}
                          </Link>
                        </li>
                      </ul>
                      <LanguageMenu />
                    </div>
                  </div>

                  <div className="-mr-2 flex lg:hidden">
                    <LanguageMenu />

                    {/* Mobile menu button */}
                    <Disclosure.Button
                      as="div"
                      className="inline-flex items-center justify-center p-2 hover:bg-green focus:outline-none cursor-pointer"
                    >
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                            color="black"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            color="black"
                          />
                        </svg>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 bg-white">
                  <Disclosure.Button
                    as="a"
                    href={DEMO_SITE}
                    className="text-black hover:!text-green hover:!bg-green/20 block px-3 py-2 text-base font-normal"
                    aria-current={"page"}
                  >
                    {t("try_demo")}
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <Link
                      className="text-black hover:!text-green hover:!bg-green/20 block px-3 py-2 text-base font-normal"
                      to={DASHBOARD_LOGIN}
                    >
                      {t("sign_in")}
                    </Link>
                  </Disclosure.Panel>
                  <Disclosure.Panel>
                    <Link
                      className="text-black hover:!text-green hover:!bg-green/20 block px-3 py-2 text-base font-normal"
                      to={DASHBOARD_SIGNUP}
                    >
                      {t("get_started")}
                    </Link>
                  </Disclosure.Panel>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </header>
      <main className="main">{children}</main>
    </RootLayout>
  );
};

export default Layout;
