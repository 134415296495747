import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DASHBOARD_SIGNUP } from "../../../urls";

const About = () => {
  const { t } = useTranslation();

  return (
    <section className="section" id="about">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 order-2 order-md-1">
            <div className="text-center">
              <img src="img/section-img-1.1.png" alt="" />
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <div className="mb-8">
              <h3 className="mb-8 font-bold text-black max-md:text-center">
                {t("about_title")}
              </h3>
              <p className="text-base text-gray mb-8">
                {t("about_description")}
              </p>
              <div className="flex max-md:justify-center">
                <Link
                  className="btn btn-primary rounded-lg px-4 py-3 lg:px-8 lg:py-6 text-base"
                  to={DASHBOARD_SIGNUP}
                >
                  {t("buy_presale_token")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
