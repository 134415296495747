import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { MEDIUM } from "../../../urls";

const Help = () => {
  const { t } = useTranslation();
  return (
    <section className="section">
      <div className="container">
        <h3 className="mb-8 font-bold text-black text-center">
          {t("need_help")}
        </h3>

        <div className="row">
          <div className="col-md-6 col-lg-4 d-flex">
            <div className="card-need-help mb-lg-0">
              <div className="card-need-help__body">
                <div className="card-need-help__icon">
                  <img src="/img/card-need-help/icon-1.svg" alt="Icon" />
                </div>

                <h6>{t("help_category_1")}</h6>
                <div className="text-block text-gray-400">
                  <p>{t("help_category_1_description")}</p>
                </div>
              </div>

              <a className="link-with-arrow" href="#footer">
                {t("help_category_1_btn")}
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex">
            <div className="card-need-help mb-lg-0">
              <div className="card-need-help__body">
                <div className="card-need-help__icon">
                  <img src="/img/card-need-help/icon-2.svg" alt="Icon" />
                </div>

                <h6>{t("help_category_2")}</h6>
                <div className="text-block text-gray-400">
                  <p>{t("help_category_2_description")}</p>
                </div>
              </div>

              <a className="link-with-arrow" href="#faqs">
                {t("help_category_2_btn")}
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex">
            <div className="card-need-help mb-lg-0">
              <div className="card-need-help__body">
                <div className="card-need-help__icon">
                  <img src="/img/card-need-help/icon-3.svg" alt="Icon" />
                </div>

                <h6>{t("help_category_3")}</h6>
                <div className="text-block text-gray-400">
                  <p>{t("help_category_3_description")}</p>
                </div>
              </div>

              <Link className="link-with-arrow" to={MEDIUM}>
                {t("help_category_3_btn")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Help;
