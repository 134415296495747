import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DASHBOARD_SIGNUP } from "../../../urls";

const Utilities = () => {
  const { t } = useTranslation();
  return (
    <section className="section bg-gradient-to-r from-white to-green/10 from-80%">
      <div className="container">
        <div className="grid max-md:gap-10 md:grid-cols-12">
          <div className="md:col-span-5 md:pr-[6.5rem] flex flex-col justify-center items-center md:items-start">
            <h3 className="mb-8 font-bold">{t("token_utilities_title")}</h3>
            <p className="text-base text-gray mb-8 max-md:text-center">
              {t("token_utilities_description")}
            </p>
            <Link
              className="btn btn-primary rounded-lg px-8 py-3 lg:px-8 lg:py-6 text-base"
              to={DASHBOARD_SIGNUP}
            >
              {t("token_utilities_btn")}
            </Link>
          </div>
          <div className="md:col-span-7">
            <div className="grid grid-cols-2 gap-10">
              <div>
                <img
                  className="w-[80px] h-[80px] mb-4"
                  src="/img/utilities/1.svg"
                  alt="Tradecurve"
                />
                <div className="font-bold text-lg text-black mb-3">
                  {t("token_utilities_category_1")}
                </div>
                <div className="font-medium font-inter text-sm text-gray">
                  {t("token_utilities_category_1_description")}
                </div>
              </div>
              <div>
                <img
                  className="w-[80px] h-[80px] mb-4"
                  src="/img/utilities/2.svg"
                  alt="Tradecurve"
                />
                <div className="font-bold text-lg text-black mb-3">
                  {t("token_utilities_category_2")}
                </div>
                <div className="font-medium font-inter text-sm text-gray">
                  {t("token_utilities_category_2_description")}
                </div>
              </div>
              <div>
                <img
                  className="w-[80px] h-[80px] mb-4"
                  src="/img/utilities/3.svg"
                  alt="Tradecurve"
                />
                <div className="font-bold text-lg text-black mb-3">
                  {t("token_utilities_category_3")}
                </div>
                <div className="font-medium font-inter text-sm text-gray">
                  {t("token_utilities_category_3_description")}
                </div>
              </div>
              <div>
                <img
                  className="w-[80px] h-[80px] mb-4"
                  src="/img/utilities/4.svg"
                  alt="Tradecurve"
                />
                <div className="font-bold text-lg text-black mb-3">
                  {t("token_utilities_category_4")}
                </div>
                <div className="font-medium font-inter text-sm text-gray">
                  {t("token_utilities_category_4_description")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Utilities;
