import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const FAQs = () => {
  const { t } = useTranslation();
  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(0);
  const questions = [
    {
      question: t("question_1"),
      answer: t("answer_1"),
    },
    {
      question: t("question_2"),
      answer: t("answer_2"),
    },
    {
      question: t("question_3"),
      answer: t("answer_3"),
    },
    {
      question: t("question_5"),
      answer: t("answer_5"),
    },
    {
      question: t("question_4"),
      answer: t("answer_6"),
    },
    {
      question: t("question_2"),
      answer: t("answer_7"),
    },
  ];
  return (
    <section className="section text-light bg-dark" id="faqs">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="mb-8 md:text-center">
              <h3 className="mb-8 font-bold text-white text-center">FAQs</h3>
              <div className="text-block fw-medium text-gray-600"></div>
            </div>
          </div>

          <div className="col-lg-10">
            <div className="accordion" id="accordion-faq">
              {questions.map((e, index) => (
                <div className="accordion-item">
                  <h6 className="accordion-header" id="accordion-faq-heading-0">
                    <button
                      className={classNames(
                        "accordion-button",
                        selectedQuestion !== index && "collapsed"
                      )}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-faq-collapse-0"
                      aria-expanded="true"
                      aria-controls="accordion-faq-collapse-0"
                      onClick={() =>
                        setSelectedQuestion((cur) =>
                          cur === index ? null : index
                        )
                      }
                    >
                      {e.question}
                    </button>
                  </h6>
                  <div
                    className={classNames(
                      "accordion-collapse",
                      selectedQuestion === index ? "show" : "collapse"
                    )}
                    id="accordion-faq-collapse-0"
                    aria-labelledby="accordion-faq-heading-0"
                    data-bs-parent="#accordion-faq"
                  >
                    <div className="accordion-body">
                      <div className="text-block fw-medium text-gray-600">
                        <p>{e.answer}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
