import { useTranslation } from "react-i18next";

const HowDoesItWork = () => {
  const { t } = useTranslation();

  return (
    <section className="section">
      <div className="container">
        <div className="text-center">
          <h3 className="mb-8 font-bold">{t("how_does_it_work")}</h3>
        </div>
        <div className="row remove-last-child-margin">
          <div className="col-md-6 col-lg-4 d-flex">
            <div className="flex flex-col items-center w-full">
              <img
                className="w-[133px] h-[133px] object-contain mb-8"
                src="/img/step_1.png"
                alt="Step"
              />
              <p className="font-bold text-lg mb-4">{t("step_1_label")}</p>
              <div className="text-block text-sm text-gray text-center">
                <p>{t("step_1_description")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 d-flex">
            <div className="flex flex-col items-center w-full">
              <img
                className="w-[133px] h-[133px] object-contain mb-8"
                src="/img/step_2.png"
                alt="Step"
              />
              <p className="font-bold text-lg mb-4">{t("step_2_label")}</p>
              <div className="text-block text-sm text-gray text-center">
                <p>{t("step_2_description")}</p>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 d-flex">
            <div className="flex flex-col items-center w-full">
              <img
                className="w-[133px] h-[133px] object-contain mb-8"
                src="/img/step_3.png"
                alt="Step"
              />
              <p className="font-bold text-lg mb-4">{t("step_3_label")}</p>
              <div className="text-block text-sm text-gray text-center">
                <p>{t("step_3_description")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowDoesItWork;
