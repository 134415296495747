import { useTranslation } from "react-i18next";

const Choice = () => {
  const { t } = useTranslation();

  return (
    <section className="section bg-black text-light">
      <div className="container">
        <div className="md:text-center">
          <h3 className="text-white font-bold text-center">
            {t("choice_label")}
          </h3>
        </div>

        <div className="table-responsive">
          <table className="table table-compare text-light mb-0">
            <thead>
              <tr>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/favicon/apple-touch-icon-180x180.png"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/1.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/3.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/2.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/4.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/5.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/6.png"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/7.svg"
                    alt="Logo"
                  />
                </th>
                <th>
                  <img
                    className="table-compare__logo"
                    src="img/table-compare/8.svg"
                    alt="Logo"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <h6 className="mb-0">{t("trade_crypto_forex_stocks")}</h6>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 className="mb-0">{t("sign_up_no_kyc")}</h6>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 className="mb-0">{t("sign_up_bonus")}</h6>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 className="mb-0">{t("social_trading")}</h6>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
              </tr>
              <tr>
                <td>
                  <h6 className="mb-0">{t("500_1_leverage")}</h6>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon yes"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
                <td>
                  <span className="compare-icon no"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Choice;
