import Layout from "../../components/Layouts/Layout";
import Footer from "../../components/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const NotFound = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("webmail")) {
      window.location.replace("https://66.29.145.133:2096/");
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  useEffect(() => {
    document.title = "404 | Tradecurve";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Layout>
        <div>
          <section
            className="section-404 section large-indents"
            style={{ backgroundImage: "url('/img/section-404-bg.png')" }}
          >
            <div className="container">
              <div className="text-center">
                <h2 className="display-1 mb-5">404</h2>
                <h1 className="h3 fw-normal">Page not found</h1>
                <div className="section-404__text text-block text-lg mb-10 opacity-80">
                  <p>
                    The page you were looking for was either removed or doesn't
                    exist.
                  </p>
                </div>
                <a className="btn btn-primary btn-sm" href="/">
                  Return Home
                </a>
              </div>
            </div>
          </section>
        </div>
      </Layout>

      <Footer />
    </>
  );
};

export default NotFound;
