import $ from "jquery";
import Sly from "sly-scroll";

$(function () {
  initTimeline();
  // PROGRESS STRIP

  function initTimeline() {
    var timeline = $(".js-timeline");

    if (timeline.length) {
      var setWidth = function setWidth() {
        var timelinePeriodList = $(".js-timeline-period-list"),
          width = 0;
        timelinePeriodList.each(function () {
          width = width + $(this).outerWidth();
        });
        timeline.find(".slidee").css("width", width);
      };
      setWidth();
      var options = {
        scrollBy: false,
        speed: 200,
        horizontal: true,
        scrollBar: ".js-timeline-scrollbar",
        dynamicHandle: true,
        dragHandle: true,
        mouseDragging: true,
        touchDragging: true,
        releaseSwing: true,
      };
      var slyTimeline = new Sly(timeline, options).init();
      $(window).on("resize", function () {
        setWidth();
        slyTimeline.reload();
      });
    }
  }
});
