import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";
import { Link } from "react-router-dom";
import { StageContext } from "../../../contexts/stage.context";
import { moneyFormat, thousandsFormat } from "../../../helpers/format";
import { DASHBOARD_SIGNUP, WHITEPAPER } from "../../../urls";

const Welcome = () => {
  const { t } = useTranslation();
  const { data } = useContext(StageContext);

  useEffect(() => {
    const progressCurrentAmount = $(".js-progress-current-amount")
      .text()
      .replace(/\D/g, "");
    const progressTotalAmount = $(".js-progress-total-amount")
      .text()
      .replace(/\D/g, "");
    const progressStrip = $(".js-progress-strip");
    const progressStripPercent = $(".js-progress-strip-percent");
    const width = (progressCurrentAmount / (progressTotalAmount / 100)).toFixed(
      2
    );
    progressStrip.css("width", width + "%");
    progressStripPercent.text(width + "% SOLD");
  }, []);

  const renderProcess = () => {
    return (
      <div className="w-auto justify-center items-start flex flex-col mb-6">
        <div className="relative rounded-2xl bg-[#E0F3EF] max-w-[516px] w-full shadow-sm">
          <div className="flex flex-col">
            <div className="px-4 py-2 flex items-center justify-between">
              <p className="text-black text-xs font-medium mb-0">
                <span className="text-black/50">{t("current_price")}: </span>$
                {data?.currentTokenPrice ?? 0.0}
              </p>

              <p className="text-black text-xs font-medium mb-0">
                <span className="text-black/50">USDT {`${t("raised")}`}: </span>
                {`${moneyFormat(data?.totalUsd ?? 0.0)} / ${moneyFormat(
                  data?.raisedUsd ?? 0
                )}`}
              </p>
            </div>

            <div className="flex flex-col px-4 py-2 bg-white rounded-2xl">
              <div className="row text-start">
                <div className="col-6 mb-3 flex items-center">
                  {/* <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2"
                  >
                    <path
                      d="M6 0.239288L12 7.0219H0L6 0.239288Z"
                      fill="#00B868"
                    />
                    <path
                      d="M6 5.97855L12 12.7612H0L6 5.97855Z"
                      fill="#00B868"
                    />
                  </svg> */}
                  {/* <span className="text-sm text-black font-medium mb-0">
                    {`${Number(data?.percentToken ?? 0).toFixed(2)}%`}{" "}
                    <span className="text-[#00B868]">{t("sold")}</span>
                  </span> */}
                </div>
                <div className="col-6 text-end">
                  <p className="text-sm text-black font-medium mb-0">
                    <span>{`${(100 - Number(data?.percentToken ?? 0)).toFixed(
                      2
                    )}%`}</span>{" "}
                    <span className="text-black/50">{t("remaining")}</span>
                  </p>
                </div>
              </div>

              <div className="progress-block__strip bg-[#DFDFE2]/20 h-8 rounded-2xl overflow-hidden">
                <div
                  className="progress-block__strip-inner -js-progress-strip js_sales_slider h-full left-0 right-0 rounded-full"
                  style={{ width: `${data?.percentToken ?? 0}%` }}
                >
                  <div className="progress-block__percent -js-progress-strip-percent js_sales_slider_percent h-full w-full relative rounded-full bg-gradient-to-r from-[#00B868]/20 from-20% to-[#C2CA60]/40">
                    <div className="bg-white h-full w-auto aspect-square absolute right-0 rounded-full shadow-md flex items-center justify-center">
                      <svg
                        width="15"
                        height="13"
                        viewBox="0 0 15 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 5.65C0.530558 5.65 0.15 6.03056 0.15 6.5C0.15 6.96944 0.530558 7.35 1 7.35L1 5.65ZM14.601 7.10104C14.933 6.7691 14.933 6.2309 14.601 5.89896L9.19167 0.489592C8.85973 0.157647 8.32154 0.157647 7.98959 0.489592C7.65765 0.821538 7.65765 1.35973 7.98959 1.69167L12.7979 6.5L7.98959 11.3083C7.65765 11.6403 7.65765 12.1785 7.98959 12.5104C8.32154 12.8424 8.85973 12.8424 9.19167 12.5104L14.601 7.10104ZM1 7.35L14 7.35V5.65L1 5.65L1 7.35Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center">
                <div className="text-block text-lg">
                  <p className="text-sm text-black">
                    <span className="font-bold">
                      {thousandsFormat(data?.totalTokensLeftByStage ?? 0)}
                    </span>{" "}
                    <span className="text-black/50">
                      {t("presale_remaining_description")}:
                    </span>{" "}
                    <span className="font-bold">
                      {data?.nextTokenPrice ?? 0} USDT
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className="section-welcome text-white bg-gradient-to-tr from-white/0 from-60% to-green/80 pb-16"
      id="welcome"
    >
      <div className="section-welcome__content">
        <div className="container">
          <div className="row mb-12 md:!mb-24">
            <div className="col-md-6">
              <div className="mb-8 mb-md-0 pt-md-15 pt-lg-20">
                <div>
                  <h1 className="text-black font-bold">
                    Next Generation <br />
                    <span className="text-green font-extrabold">
                      TradFi
                    </span>{" "}
                    Platform
                  </h1>
                  <div className="row">
                    <div className="col-xl-10">
                      <div className="text-block text-lg text-black font-light mb-6">
                        <p>{t("banner_description")}</p>
                      </div>
                    </div>
                  </div>

                  {/* {renderProcess()} */}

                  <div className="flex gap-6">
                    {/* <Link
                      className="btn btn-primary rounded-lg px-4 py-3 lg:px-8 lg:py-6"
                      to={DASHBOARD_SIGNUP}
                    >
                      {t("presale_complete")}
                    </Link> */}

                    <Link
                      className="btn btn-info rounded-lg px-4 py-3 lg:px-8 lg:py-6"
                      to={"https://dexscreener.com/ethereum/0x1215cdbb88f9bb8ba0c2b0e6c4e6b9acb44428ee"}
                      target="_blank"
                    >
                      DEX Screener
                    </Link>

                    <Link
                      className="btn btn-danger bg-pink-400 border-pink-400 rounded-lg px-4 py-3 lg:px-8 lg:py-6"
                      to={"https://app.uniswap.org/explore/tokens/ethereum/0x923607a06aa8f1c525a5bb2d921cf85e625be776"}
                      target="_blank"
                    >
                      Uniswap
                    </Link>

                    

                    <Link
                      className="btn btn-outline-primary text-black border-black hover:!text-white rounded-lg px-4 py-3 lg:px-8 lg:py-6"
                      to={WHITEPAPER}
                      target="_blank"
                    >
                      {t("whitepaper")}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center text-md-start">
              <div className="section-welcome__img">
                <img
                  className="w-auto h-auto"
                  src="/img/section-welcome-img-1.png"
                  alt="PC"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h6 className="text-xl font-medium text-black mb-14 text-center">
              {t("as_seen_in")}
            </h6>
            <div className="flex flex-col">
              <div className="flex flex-wrap gap-x-8 gap-y-8 lg:gap-x-16 justify-between">
                <img
                  src="/images/logo_1.png"
                  className="h-8 w-auto object-contain"
                  alt="Logo"
                />
                <img
                  src="/images/logo_3.png"
                  className="h-8 w-auto object-contain"
                  alt="Logo"
                />
                <img
                  src="/images/logo_4.png"
                  className="h-8 w-auto object-contain"
                  alt="Logo"
                />
                <img
                  src="/images/logo_2.png"
                  className="h-8 w-auto object-contain"
                  alt="Logo"
                />
                <img
                  src="/images/logo_5.png"
                  className="h-8 w-auto object-contain"
                  alt="Logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
