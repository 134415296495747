import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

interface MainData {
  totalRaised: string;
  currentTokenPrice: string;
  totalTokens: string;
  totalTokensByStage: string;
  totalSold: string;
  totalSoldByStage: string;
  raisedUsd: string;
  totalUsdByStage: string;
  totalUsd: string;
  raisedUsdByStage: string;
  totalTokensLeftByStage: string;
  percentToken: string;
  stageName: string;
  nextTokenPrice: string;
}

interface StageContextProps {
  data: MainData | null;
}

const initialContext: StageContextProps = {
  data: null,
};

export const StageContext = createContext<StageContextProps>(initialContext);

export const StageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<MainData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data,
        }: {
          data: {
            total_raised: string;
            current_token_price: string;
            total_tokens: string;
            total_tokens_by_stage: string;
            total_sold: string;
            total_sold_by_stage: string;
            raised_usd: string;
            total_usd_by_stage: string;
            total_usd: string;
            raised_usd_by_stage: string;
            total_tokens_left_by_stage: string;
            percent_token: string;
            stage: {
              stage_name: string;
            };
            next_token_price: string;
          };
        } = await axios.get(process.env.REACT_APP_API_URL + "/main");
        setData({
          totalRaised: data.total_raised,
          currentTokenPrice: data.current_token_price,
          totalTokens: data.total_tokens,
          totalTokensByStage: data.total_tokens_by_stage,
          totalSold: data.total_sold,
          totalSoldByStage: data.total_sold_by_stage,
          raisedUsd: data.raised_usd,
          totalUsdByStage: data.total_usd_by_stage,
          totalUsd: data.total_usd,
          raisedUsdByStage: data.raised_usd_by_stage,
          totalTokensLeftByStage: data.total_tokens_left_by_stage,
          percentToken: data.percent_token,
          stageName: data.stage?.stage_name ?? "Stage 1",
          nextTokenPrice: data.next_token_price,
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <StageContext.Provider value={{ data }}>{children}</StageContext.Provider>
  );
};
