import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

const Year: React.FC<{ year: number; position: "right" | "left" }> = ({
  year,
  position,
}) => {
  return (
    <div className="w-full h-full flex items-center justify-center relative">
      <div className="w-[3px] bg-[#323232] h-full" />
      {position === "left" && (
        <div className="h-[3px] w-1/2 bg-[#323232] absolute right-0 left-auto md:left-0 md:right-auto" />
      )}
      {position === "right" && (
        <div className="h-[3px] w-1/2 bg-[#323232] absolute right-0" />
      )}

      <div className="w-12 h-12 md:w-16 md:h-16 bg-[#151515] rounded-full flex items-center justify-center absolute">
        {year}
      </div>
    </div>
  );
};

const RightCard: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="relative md:w-10/12 mb-10">
      <div className="bg-[#151515] p-8 w-full">{children}</div>
      <div className="h-[95%] border !border-transparent !border-r-green absolute top-0 right-0"></div>
      <img
        src="/img/roadmap_icon.png"
        className="h-8 w-[3.25rem] object-contain absolute -bottom-4 -right-[1.625rem]"
        alt="RoadmapIcon"
      />
    </div>
  );
};

const LeftCard: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="hidden md:block relative md:w-10/12 mb-10">
      <div className="bg-[#151515] p-8 w-full">{children}</div>
      <div className="h-[95%] border !border-transparent !border-r-green absolute top-0 left-0"></div>
      <img
        src="/img/roadmap_icon.png"
        className="h-8 w-[3.25rem] object-contain absolute -bottom-4 -left-[1.625rem]"
        alt="RoadmapIcon"
      />
    </div>
  );
};

const Roadmap = () => {
  const { t } = useTranslation();

  return (
    <section className="section text-light bg-black overflow-hidden">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8">
            <div className="mb-12 md:text-center">
              <h3 className="mb-8 font-bold text-white text-center">
                {t("roadmap_label")}
              </h3>
              <div className="text-block text-center text-gray-600">
                <p>{t("roadmap_description")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="hidden md:flex col-span-5 flex-col items-end"></div>
          <div className="col-span-2">
            <Year year={2023} position="right" />
          </div>
          <div className="col-span-10 md:col-span-5">
            <RightCard>
              <h6>{t("stage")} 1</h6>
              <ul className="text-gray">
                <li className="mb-0">{t("stage_1_1")}</li>
                <li className="mb-0">{t("stage_1_2")}</li>
                <li className="mb-0">{t("stage_1_3")}</li>
                <li className="mb-0">{t("stage_1_4")}</li>
                <li className="mb-0">{t("stage_1_5")}</li>
                <li className="mb-0">{t("stage_1_6")}</li>
                <li className="mb-0">{t("stage_1_7")}</li>
              </ul>
            </RightCard>
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="hidden md:flex col-span-5 flex-col items-end">
            <LeftCard>
              <h6>{t("stage")} 2</h6>
              <ul className="text-gray">
                <li className="mb-0">{t("stage_2_1")}</li>
                <li className="mb-0">{t("stage_2_2")}</li>
                <li className="mb-0">{t("stage_2_3")}</li>
                <li className="mb-0">{t("stage_2_4")}</li>
                <li className="mb-0">{t("stage_2_5")}</li>
                <li className="mb-0">{t("stage_2_6")}</li>
                <li className="mb-0">{t("stage_2_7")}</li>
              </ul>
            </LeftCard>
          </div>
          <div className="col-span-2">
            <Year year={2023} position="left" />
          </div>
          <div className="col-span-10 md:col-span-5">
            <div className="block md:hidden">
              <RightCard>
                <h6>{t("stage")} 2</h6>
                <ul className="text-gray">
                  <li className="mb-0">{t("stage_2_1")}</li>
                  <li className="mb-0">{t("stage_2_2")}</li>
                  <li className="mb-0">{t("stage_2_3")}</li>
                  <li className="mb-0">{t("stage_2_4")}</li>
                  <li className="mb-0">{t("stage_2_5")}</li>
                  <li className="mb-0">{t("stage_2_6")}</li>
                  <li className="mb-0">{t("stage_2_7")}</li>
                </ul>
              </RightCard>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="hidden md:flex col-span-5 flex-col items-end"></div>
          <div className="col-span-2">
            <Year year={2024} position="right" />
          </div>
          <div className="col-span-10 md:col-span-5">
            <RightCard>
              <h6>{t("stage")} 3</h6>
              <ul className="text-gray">
                <li className="mb-0">{t("stage_3_1")}</li>
                <li className="mb-0">{t("stage_3_2")}</li>
                <li className="mb-0">{t("stage_3_3")}</li>
                <li className="mb-0">{t("stage_3_4")}</li>
                <li className="mb-0">{t("stage_3_5")}</li>
              </ul>
            </RightCard>
          </div>
        </div>

        <div className="grid grid-cols-12">
          <div className="hidden md:flex col-span-5 flex-col items-end">
            <LeftCard>
              <h6>{t("stage")} 4</h6>
              <ul className="text-gray">
                <li className="mb-0">{t("stage_4_1")}</li>
                <li className="mb-0">{t("stage_4_2")}</li>
                <li className="mb-0">{t("stage_4_3")}</li>
              </ul>
            </LeftCard>
          </div>
          <div className="col-span-2">
            <Year year={2024} position="left" />
          </div>
          <div className="col-span-10 md:col-span-5">
            <div className="block md:hidden">
              <RightCard>
                <h6>{t("stage")} 4</h6>
                <ul className="text-gray">
                  <li className="mb-0">{t("stage_4_1")}</li>
                  <li className="mb-0">{t("stage_4_2")}</li>
                  <li className="mb-0">{t("stage_4_3")}</li>
                </ul>
              </RightCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
